import React from 'react';

export default class Footer extends React.Component {
	
    componentDidMount() {
    }

    render() {

        return (
        		<footer>
                <div className="container-fluid">
                    <div className="row">
                        <div className="container padding-def">
                            <div className="col-lg-1  col-sm-2 col-xs-12 footer-logo">
                            	
                            </div>
                            
                            <div className="col-lg-7 col-sm-6 col-xs-12">
                                <div className="f-copy">
                                    <ul className="list-inline">
                                    <li>Created by <a href="https://www.linkedin.com/in/rameshdhason" style={{color:'#337ab7'}}>Ramesh Dhason</a></li>
                                    <li> &copy; copyright 2020</li>
                                    </ul>
                                </div>
                                
                                
                            </div>
                            <div className="col-lg-offset-1 col-lg-3 col-sm-4 col-xs-12">
                                <div className="f-last-line">
                                    <div className="f-icon pull-left">
                                        <a href="https://www.linkedin.com/in/rameshdhason/"><i className="fa fa-linkedin"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-github"></i></a>
                                    </div>
                                    <div className="f-lang pull-right">
                                        <div className="btn-group dropup pull-right">
                                            <ul className="dropdown-menu">
                                                <li><a href="#"><i className="cv cvicon-cv-relevant"></i> Relevant</a></li>
                                                <li><a href="#"><i className="cv cvicon-cv-calender"></i> Recent</a></li>
                                                <li><a href="#"><i className="cv cvicon-cv-view-stats"></i> Viewed</a></li>
                                                <li><a href="#"><i className="cv cvicon-cv-star"></i> Top Rated</a></li>
                                                <li><a href="#"><i className="cv cvicon-cv-watch-later"></i> Longest</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="delimiter">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>   
        );
    }
}


