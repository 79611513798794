import firebase from 'firebase'

var firebaseConfig = {
    apiKey: "AIzaSyBwP0Up-va4x42Atz0r0HAqRF7M4VBkllI",
    authDomain: "tolstoy-e0c47.firebaseapp.com",
    databaseURL: "https://tolstoy-e0c47.firebaseio.com",
    projectId: "tolstoy-e0c47",
    storageBucket: "tolstoy-e0c47.appspot.com",
    messagingSenderId: "288687280212",
    appId: "1:288687280212:web:cb4af7bbbc111af8009dc1"
}

firebase.initializeApp(firebaseConfig);

export default firebase;