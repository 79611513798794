import React from 'react';
//import VideoConference from './components/videoconference';
//import ScreenShare from './components/screenshare';
import ScreenMultiShare from './components/screenmultishare';
import Footer from './components/footer';
import './App.css';


function App() {
  return (
	<>
	
	<ScreenMultiShare/>
	
	<Footer/>
	</>
  );
}

export default App;
