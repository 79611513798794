import React from 'react';
import firebase from './firebase';
import copy from 'copy-to-clipboard';
import { first } from "lodash";
import adapter from 'webrtc-adapter';
import Loader from 'react-loader-spinner'


const configuration = {
  iceServers: [
    {
      urls: [
    	  'stun:stun.l.google.com:19302',
          'stun:stun1.l.google.com:19302',
          'stun:stun2.l.google.com:19302',
          'stun:stun.l.google.com:19302?transport=udp'
      ],
    },
  ],
  iceCandidatePoolSize: 10,
};

let peerConnection = null;
let localStream = null;
let remoteStream = null;
let roomId = null;

	

export default class ScreenMultiShare extends React.Component {
	
	constructor(props){
		super(props);
		let url = window.location.href;
		let baseUrl = url;
		
		var index = url.indexOf("#");
		var roomId = null;
		if(index != -1){
			roomId = url.substring(index+1);
			baseUrl = url.substring(0,index);
		}
		
		let sharing = false;
		let viewing = false;
		let viewer = "N";
		
		if(roomId !== null && roomId.trim() !== ''){
			viewer = "Y";
		}
		this.videoRef = React.createRef();
		
		this.state = {
			roomId : roomId,
			sharing: sharing,
			viewer : viewer,
			viewing: viewing,
			ended: false,
			baseUrl: baseUrl,
			linkCopied: false,
			loading: false
		};
		
		this.shareScreen = this.shareScreen.bind(this);
		this.afterShareScreen = this.afterShareScreen.bind(this);
		this.stopSharing = this.stopSharing.bind(this);
		
		this.viewScreen = this.viewScreen.bind(this);
		this.stopViewing = this.stopViewing.bind(this);
		this.iceStateCallback1 = this.iceStateCallback1.bind(this);
	}
	
	copyLink(){
		copy(this.state.baseUrl + '#' +this.state.roomId);
		this.setState({
			linkCopied : true
		});
	}
	
	
	stopSharing() {
//      const tracks = document.querySelector('#localVideo').srcObject.getTracks();
//  	  tracks.forEach(track => {
//  	    track.stop();
//  	  });

  	  if(peerConnection) {
  	    peerConnection.close();
  	  }
  	  
  	  if (localStream) {
  		localStream.getTracks().forEach(track => track.stop());
  	  }

  	  let roomId = this.state.roomId;
  	  if (roomId) {
  	    const db = firebase.firestore();
  	    const roomRef = db.collection('rooms').doc(roomId);
  	    
  	    roomRef.collection('calleeCandidates').get().then(calleeCandidates => {
  	    	calleeCandidates.forEach(async candidate => {
  	  	      candidate.ref.delete();
  	  	    });
  	    });
  	    roomRef.delete();
  	  }
  	  
  	  this.setState({
    		roomId : null,
    		sharing: false,
    		viewing: false,
    		ended: false
       });
  	}
    
    shareScreen() {
    	window.history.replaceState(null, null, "#");
    	this.setState({ended:false, loading:false});
    	
    	navigator.mediaDevices.getDisplayMedia({video: true, audio: false})
	    .then(stream => {
	    	
	    	this.afterShareScreen(stream);
	    }).catch(console.log);
    }
    
    stateCallback1() {
    	  let state;
    	  if (peerConnection) {
    	    const pc1StateDiv = document.querySelector('div#pc1State');
    	    	
    	    state = peerConnection.signalingState || peerConnection.readyState;
    	    pc1StateDiv.textContent += ` => ${state}`;
    	  }
    }
    
    iceStateCallback1() {
    	  let iceState;
    	  if (peerConnection) {
    		const pc1IceStateDiv = document.querySelector('div#pc1IceState');
    	    iceState = peerConnection.iceConnectionState;
    	    pc1IceStateDiv.textContent += ` => ${iceState}`;
    	    
    	    if (peerConnection.iceConnectionState === 'connected' ||
    	    	peerConnection.iceConnectionState === 'completed') {
    	    	this.checkStats(peerConnection);
    	    	
    	    	setTimeout(
    	    		    function() {
    	    		        this.setState({loading: false, viewing: true});
    	    		    }
    	    		    .bind(this),
    	    		    2000
    	    	 );
    	    	
    	    }
    	    
    	    if (peerConnection.iceConnectionState === 'disconnected' ||
        	    	peerConnection.iceConnectionState === 'failed') {
    	    	
    	    	this.setState({loading: false, ended: true, viewing: false, viewer: 'N', sharing: false});
    	    }
    	    
    	  }
    }
    
    connStateCallback1() {
    	  if (peerConnection) {
    	    const {connectionState} = peerConnection;
    	    const pc1ConnStateDiv = document.querySelector('div#pc1ConnState');
    	    pc1ConnStateDiv.textContent += ` => ${connectionState}`;
    	  }
    }
    
     onIceCandidate(pc, event) {
    	  this.getOtherPc(pc)
    	      .addIceCandidate(event.candidate)
    	      .then(() => this.onAddIceCandidateSuccess(pc), err => this.onAddIceCandidateError(pc, err));
     }
     
     getOtherPc(pc) {
    	return (pc === peerConnection) ? null : peerConnection;
     }
     
     onAddIceCandidateSuccess() {
    	  console.log('AddIceCandidate success.');
     }

     onAddIceCandidateError(error) {
    	  console.log(`Failed to add Ice Candidate: ${error.toString()}`);
     }
    
     afterShareScreen(stream){
    	
    		
//    	const screenTrack = first(stream.getVideoTracks());
    	
//    	document.querySelector('#localVideo').srcObject = stream;
    	localStream = stream;
    	
    	let db = firebase.firestore();
        let roomRef = db.collection('rooms').doc();
        
        peerConnection = new RTCPeerConnection(configuration);
        
        const pc1StateDiv = document.querySelector('div#pc1State');
    	const pc1IceStateDiv = document.querySelector('div#pc1IceState');
    
      	pc1StateDiv.textContent = peerConnection.signalingState || peerConnection.readyState;
    	peerConnection.onsignalingstatechange = this.stateCallback1;
    	
    	pc1IceStateDiv.textContent = peerConnection.iceConnectionState;
    	peerConnection.oniceconnectionstatechange = this.iceStateCallback1;
    	
    	peerConnection.onconnectionstatechange = this.connStateCallback1;
    	  
        
        localStream.getTracks().forEach(track => {
  	      peerConnection.addTrack(track, localStream);
  	    });
        
        const callerCandidatesCollection = roomRef.collection('callerCandidates');
        
        peerConnection.addEventListener('icecandidate', event => {
    	    if (!event.candidate) {
    	      return;
    	    }
    	    callerCandidatesCollection.add(event.candidate.toJSON());
    	});
        
        peerConnection.createOffer().then(offer => {
        	peerConnection.setLocalDescription(offer);
        	
        	const roomWithOffer = {
        	  	    'offer': {
        	  	      type: offer.type,
        	  	      sdp: offer.sdp,
        	  	    }
          	};
        	  	  
        	roomRef.set(roomWithOffer);
        	roomId = roomRef.id;
        	
        	peerConnection.addEventListener('track', event => {
        		event.streams[0].getTracks().forEach(track => {
        	      remoteStream.addTrack(track);
    	  	    });
    	  	});
        	
        	roomRef.onSnapshot(snapshot => {
    	  	    const data = snapshot.data();
    	  	    if (!peerConnection.currentRemoteDescription && data && data.answer) {
    	  	      const rtcSessionDescription = new RTCSessionDescription(data.answer);
    	  	      peerConnection.setRemoteDescription(rtcSessionDescription);
    	  	    }
    	  	});
        	
        	roomRef.collection('calleeCandidates').onSnapshot(snapshot => {
    	  	    snapshot.docChanges().forEach(change => {
    	  	      if (change.type === 'added') {
    	  	        let data = change.doc.data();
    	  	        peerConnection.addIceCandidate(new RTCIceCandidate(data));
    	  	      }
    	  	    });
    	  	});
        	
        	this.setState({
    	   		roomId : roomId,
    	   		sharing: true,
    	   		linkCopied : false
    	   	});
    	  	  
        	
        });
    }
     
    checkStats(pc) {
    	  pc.getStats(null).then(results => {
    	    // figure out the peer's ip
    	    let activeCandidatePair = null;
    	    let remoteCandidate = null;

    	    // Search for the candidate pair, spec-way first.
    	    results.forEach(report => {
    	      if (report.type === 'transport') {
    	        activeCandidatePair = results.get(report.selectedCandidatePairId);
    	      }
    	    });
    	    // Fallback for Firefox.
    	    if (!activeCandidatePair) {
    	      results.forEach(report => {
    	        if (report.type === 'candidate-pair' && report.state === 'succeeded' && report.selected) {
    	          activeCandidatePair = report;
    	        }
    	      });
    	    }
    	    if (activeCandidatePair && activeCandidatePair.remoteCandidateId) {
    	      results.forEach(report => {
    	        if (report.type === 'remote-candidate' && report.id === activeCandidatePair.remoteCandidateId) {
    	          remoteCandidate = report;
    	        }
    	      });
    	    }
    	    console.log(remoteCandidate);
    	    if (remoteCandidate && remoteCandidate.id) {
//    	      document.getElementById(pc === pc1 ? 'localCandidateId' : 'remoteCandidateId').textContent = remoteCandidate.id;
    	    }
    	  });
    }

    viewScreen() {
      this.setState({loading: true});
    	
      let roomId = this.state.roomId;
   	  const db = firebase.firestore();
  	  const roomRef = db.collection('rooms').doc(`${roomId}`);
  	  
  	  roomRef.get().then(roomSnapshot => {
  		  
  	  	remoteStream = new MediaStream();
  	  	document.querySelector('#localVideo').srcObject = remoteStream;
  		
  	  	if (roomSnapshot.exists) {
	  	    peerConnection = new RTCPeerConnection(configuration);
	  	    
	  	    const pc1StateDiv = document.querySelector('div#pc1State');
	    	const pc1IceStateDiv = document.querySelector('div#pc1IceState');
	    
	      	pc1StateDiv.textContent = peerConnection.signalingState || peerConnection.readyState;
	    	peerConnection.onsignalingstatechange = this.stateCallback1;
	    	
	    	pc1IceStateDiv.textContent = peerConnection.iceConnectionState;
	    	peerConnection.oniceconnectionstatechange = this.iceStateCallback1;
	    	
	    	peerConnection.onconnectionstatechange = this.connStateCallback1;
	    	
	  	    const calleeCandidatesCollection = roomRef.collection('calleeCandidates');
	  	    
	  	    peerConnection.addEventListener('icecandidate', event => {
	  	      if (!event.candidate) {
	  	        return;
	  	      }
	  	      calleeCandidatesCollection.add(event.candidate.toJSON());
	  	    });
	
	  	    peerConnection.addEventListener('track', event => {
	  	      event.streams[0].getTracks().forEach(track => {
	  	        remoteStream.addTrack(track);
	  	      });
	  	    });
	
	  	    const offer = roomSnapshot.data().offer;
	  	    
	  	    peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
	  	    
	  	    peerConnection.createAnswer().then(answer => {

	  	    	peerConnection.setLocalDescription(answer);
	  	 
	  	    	const roomWithAnswer = {
	  		  	      answer: {
	  		  	        type: answer.type,
	  		  	        sdp: answer.sdp,
	  		  	      }
	  		  	};
	  		  	
	  	    	roomRef.update(roomWithAnswer);
	  		  	roomRef.collection('callerCandidates').onSnapshot(snapshot => {
  		  	      snapshot.docChanges().forEach(change => {
  		  	        if (change.type === 'added') {
  		  	          let data = change.doc.data();
  		  	          peerConnection.addIceCandidate(new RTCIceCandidate(data))
  		  	          .then(() => this.onAddIceCandidateSuccess(peerConnection), err => this.onAddIceCandidateError(peerConnection, err));
//  		  	          console.log(peerConnection.iceConnectionState);
  		  	        }
  		  	      });
  		  	    });
	  			  	
  		  	    
  		  	    
	  	    });
  	  	}
  	  	else{
  	  		this.setState({loading: false, ended: true, viewing: false, viewer: 'N', sharing: false});
	  	}
  	  	
  	  });
  	}
    
    stopViewing() {
    	window.history.replaceState(null, null, "#");
        const tracks = document.querySelector('#localVideo').srcObject.getTracks();
    	  tracks.forEach(track => {
    	    track.stop();
    	  });

    	  if(peerConnection) {
    	    peerConnection.close();
    	  }
    	  
    	  if (remoteStream) {
    		  remoteStream.getTracks().forEach(track => track.stop());
    	  }

    	  this.setState({
      		roomId : null,
      		sharing: false,
      		viewing: false,
      		ended: false,
      		viewer : 'N',
      		linkCopied : false
         });
    }

    render() {

        return (
        
        <>		
        <div className="container-fluid hidden-xs hidden-sm">
        	    <div className="row">
        	        <div className="navbar-container navbar-fixed-top">
        	            <div className="container">
        	                <div className="row">
        	                    <div className="col-lg-3 col-sm-12 col-xs-12">
        	                    	<a className="navbar-brand" href="/">
        	                            <img src={require('../assets/images/logo.svg')}  alt="Project name" className="logo" />
        	                        </a>
        	                        <div className="brand-name">teles</div>
        	                    </div>
        	                    
        	                    <div className="col-lg-7 col-sm-8 col-xs-3">
        	                    {(this.state.viewer === "N" && this.state.sharing && this.state.roomId != null) && 
        	                    	<ul className="list-inline menu">
        	                    		
        	                    	<li style={{fontWeight: '700', color:'blue'}}> {this.state.baseUrl + '#' +this.state.roomId} </li>
	                    			<li> <button className="btn-copy" onClick={() => this.copyLink()}>Copy Link</button></li>
        	                    	
	                    			{this.state.linkCopied &&
        	                    		<li> Link Copied </li>	
        	                    		}
                                		
                                	</ul>
        	                    }
        	                    </div>
        	                    <div className="col-lg-1 col-sm-3">
        	                    <div className="pull-left">
        	                    	{/*(this.state.viewer === "N" && this.state.sharing) &&
        	                    		<button className="btn btn-primary nav-btn" onClick={() => this.stopSharing()}>Stop Sharing</button>
        	                    	*/}
        	                    	
        	                    	{(this.state.viewer === "Y" && this.state.viewing) &&
        	                    		<button className="btn btn-primary nav-btn" onClick={() => this.stopViewing()}>Stop Viewing</button>
        	                    	}
	                    		</div>	
	                    		</div>
        	                </div>
        	                
        	            </div>
        	        </div>
        	    </div>
        </div>		
        		
        <div className="content-wrapper hidden-xs hidden-sm">

        <div className="container" id="states" style={{display:'none'}}>
        
        
        <div className="row">
        <div>
            <div className="u-text1">PC1 state:</div>
            <div id="pc1State" className="u-text1"></div>
        </div>
        <div>
            <div className="u-text1">PC1 ICE state:</div>
            <div id="pc1IceState" className="value"></div>
        </div>
        <div>
            <div className="u-text1">PC1 connection state:</div>
            <div id="pc1ConnState" className="value"></div>
        </div>
        <div>
            <div className="u-text1">PC2 state:</div>
            <div id="pc2State" className="value"></div>
        </div>
        <div>
            <div className="u-text1">PC2 ICE state:</div>
            <div id="pc2IceState" className="u-text1"></div>
        </div>
        <div>
            <div className="u-text1">PC2 connection state:</div>
            <div id="pc2ConnState" className="u-text1"></div>
        </div>
        </div>
    </div>
        
    
    {( this.state.loading) &&
   	 <div className="col-lg-12 upload-page">
        <div className="u-loaderarea">
   	 	<Loader type="Oval" color="#ea2c5a" height={100} width={100}/>
   	 	</div>
   	 	
   	   <div className="u-terms">
		   	<p>By using teles, you acknowledge that you agree to use the screen sharing at your sole risk and teles will not be liable for any costs or damages</p>
		  	<p className="hidden-xs">Please be sure not to violate other's copyrights or privacy rights. <a href="https://copyright.gov/">Learn more</a></p>
       </div>
   	 	
        </div>
    }
    
         {(!this.state.loading &&  this.state.viewer === "N" && !this.state.sharing) &&
        	
        	 <div className="container-fluid">
         		{this.state.ended &&
         			<div className="row">
         		 	<div className="col-lg-4 col-lg-offset-4">
         		 		<div className="isa_error">Screen sharing has ended! Please request a new link.</div>
         			</div>
         			</div>
         		}
         
         		<div className="row">
                    <div className="col-lg-12 upload-page">
                     	<div className="u-area">
                            {/*<i className="cv cvicon-cv-upload-video"></i>*/}
                            <img src={require('../assets/images/monitor-screen.png')} style={{height:'200px', width: 'auto'}}/>
                            <p className="u-text1">Share your screen</p>
                            <p className="u-text2">Copy the link and share it! It's that simple</p>
                            <button className="btn btn-primary u-btn" onClick={() => this.shareScreen()}>Share Screen</button>
                        </div>
                      
                      
                        <div className="u-terms">
                      	<p>By using teles, you acknowledge that you agree to use the screen sharing at your sole risk and teles will not be liable for any costs or damages</p>
                     	<p className="hidden-xs">Please be sure not to violate other's copyrights or privacy rights. <a href="https://copyright.gov/">Learn more</a></p>
                          </div>
                    </div>
                </div>
              </div>
          }
         
         
         {(!this.state.loading &&  this.state.viewer === "N" && this.state.sharing) &&
         	
        	 <div className="container-fluid">
         
         		<div className="row">
                    <div className="col-lg-12 upload-page">
                     	<div className="u-area" style={{paddingTop:'210px'}}>
                            <Loader type="ThreeDots" color="#aaa" height={100} width={100}/>
                            <p className="u-text1">You are sharing your screen</p>
                            <p className="u-text2">Copy the link at the top and share it!</p>
                            
                            <button className="btn btn-primary stop-btn" onClick={() => this.stopSharing()}>Stop Sharing</button>
                        </div>
                      
                      
                        <div className="u-terms">
                      	<p>By using teles, you acknowledge that you agree to use the screen sharing at your sole risk and teles will not be liable for any costs or damages</p>
                     	<p className="hidden-xs">Please be sure not to violate other's copyrights or privacy rights. <a href="https://copyright.gov/">Learn more</a></p>
                          </div>
                    </div>
                </div>
              </div>
          }
         
         {(!this.state.loading &&  this.state.viewer === "Y" && !this.state.viewing) &&
         
        	<div className="container-fluid">
         		 <div className="row">
		         <div className="col-lg-12 upload-page">
		         	    
		         <div className="u-area">
		        	    	<img src={require('../assets/images/monitor-screen.png')} style={{height:'200px', width: 'auto'}}/>
			                 <p className="u-text1">View screen</p>
			                 <p className="u-text2">Please click the button to view the screen</p>
			                 <button className="btn btn-primary u-btn" onClick={() => this.viewScreen()}>View Screen</button>
			             </div>
			             
			             <div className="u-terms">
			             	<p>By using teles, you acknowledge that you agree to use the screen sharing at your sole risk and teles will not be liable for any costs or damages</p>
                         	<p className="hidden-xs">Please be sure not to violate other's copyrights or privacy rights. <a href="https://copyright.gov/">Learn more</a></p>
                         </div>
		         </div>
		         </div>
		         
         	</div>
         }
         
         
         
         <div className="container">
         <div className="row">
         	<video  className={(!this.state.ended && (this.state.viewing)) ? 'showPlayer' : 'hidePlayer'} id="localVideo" muted autoPlay></video>
         </div>
         </div>
         
        </div>
        
        <div className="content-wrapper hidden-xl hidden-lg hidden-md">
        
        <div className="navbar-container navbar-fixed-top">
        <div className="container">
        <div className="row">	        
	    <div className="col-12">
        	<a className="navbar-brand" href="/">
                <img src={require('../assets/images/logo.svg')}  alt="Project name" className="logo" />
                <span className="brand-name">teles</span>
            </a>
           
        </div>
        </div>
        </div>
        </div>
        
	        <div className="container-fluid">
	        <div className="row">
			 <div className="col-lg-12 upload-page">
	       	     <div className="u-area">
		                 <img src={require('../assets/images/monitor-screen.png')} style={{height:'200px', width: 'auto'}}/>
		                 <p className="u-text1">Device is not supported</p>
		                 <p className="u-text2">Please use a laptop or desktop device for screen sharing</p>
		             </div>
		    </div>
	        </div>
	        
	        </div>
        </div>
        
        </>	
        	
	    );
    }
}
